import { Button } from '@components/Atoms/Button/Button';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  modalOpen: boolean;
  closeModal: () => void;
  title: string;
  children?: ReactNode;
  showClose?: boolean;
};

export const Modal: FC<ModalProps> = ({ title, modalOpen, closeModal, children, showClose = false }) => {
  const modalContent = (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog as="div" className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto" onClose={closeModal}>
        <div className="tw-fixed tw-inset-0 tw-overflow-visible tw-bg-black/50" aria-hidden="true" />
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
          <TransitionChild
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <DialogPanel
              className="tw-relative tw-w-full tw-max-w-md tw-rounded-xl tw-bg-white tw-p-6 tw-backdrop-blur-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="tw-flex tw-justify-between tw-align-middle">
                <DialogTitle as="h3" className="tw-font-lg tw-my-4 tw-text-center">
                  {title}
                </DialogTitle>
                {showClose && (
                  <div className="tw-flex tw-items-center">
                    <Button className="tw-text-lg" variant="borderless" onClick={closeModal}>
                      X
                    </Button>
                  </div>
                )}
              </div>
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
  return createPortal(modalContent, document.body);
};
