import { SkeletonTable } from '@components/Molecules/SkeletonTable/SkeletonTable';
import { useCallback, useMemo } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeprecatedSimpleTable from '../../../../components/Organisms/SimpleTable/DeprecatedSimpleTable';
import { useGetAllNetworksQuery } from '../network-api-slice';

type NetworksTableProps = {
  allNetworkResponseIsFetching: boolean;
};
const NetworksTable = ({ allNetworkResponseIsFetching }: NetworksTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (row) => {
      if (row.network_state !== 'Terminated') {
        navigate(`/dashboard/private-network/${row.network_id}/`);
      }
    },
    [navigate],
  );

  const { data: networks } = useGetAllNetworksQuery();

  const columns = [
    {
      Header: 'ID',
      accessor: 'network_id',
    },
    {
      Header: 'NAME',
      accessor: 'network_name',
    },
    {
      Header: 'Subnet',
      accessor: 'subnet',
    },
    {
      Header: 'Size',
      accessor: 'hosts',
    },
    {
      Header: 'Internet',
      accessor: 'internet_access',
    },
    {
      Header: 'State',
      accessor: 'network_state',
      Cell: ({ value }) => {
        let color = 'warning';
        if (value === 'Terminated') {
          color = 'danger';
        } else if (value === 'Deployed') {
          color = 'success';
        }
        return (
          <Badge className="d-flex align-items-center justify-content-center" bg={color}>
            {value}
          </Badge>
        );
      },
    },
  ];

  const skeletonColumns = useMemo(() => {
    return columns.map((column) => column.Header);
  }, [columns]);

  const handleNewPrivateNetworkClick = () => {
    navigate('/dashboard/private-network/create?step=configure');
  };

  return (
    <div>
      {allNetworkResponseIsFetching ? (
        <SkeletonTable
          columns={skeletonColumns}
          headerClassname="tw-text-[#888888] tw-text-xs tw-font-bold"
          tableHeader={t('privateNetwork.title')}
        />
      ) : (
        <DeprecatedSimpleTable
          title={t('privateNetwork.title')}
          hasPagination={false}
          data={networks}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          customActionButton={
            <Button className="text-nowrap" variant="primary" size="sm" onClick={handleNewPrivateNetworkClick}>
              {t('privateNetwork.createNew')}
            </Button>
          }
          onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

export default NetworksTable;
