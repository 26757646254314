import { Navigate } from 'react-router-dom';
import { useGetAllNetworksQuery } from '../network-api-slice';
import Networks from './Networks';

type NetworkProps = {
  sidebarOpen: boolean;
};
const Network = ({ sidebarOpen }: NetworkProps) => {
  const getAllNetworkResponse = useGetAllNetworksQuery();
  const networks = getAllNetworkResponse.data || [];

  if (!getAllNetworkResponse.isFetching && networks.length === 0) {
    // Redirect to create network if there aren't any networks
    return <Navigate replace to="/dashboard/private-network/create" />;
  }

  return <Networks sidebarOpen={sidebarOpen} allNetworkResponseIsFetching={getAllNetworkResponse.isFetching} />;
};

export default Network;
