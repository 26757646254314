import { OverviewHeader } from '../../components';
import NetworksTable from './NetworksTable';

type NetworksProps = {
  sidebarOpen: boolean;
  allNetworkResponseIsFetching: boolean;
};
const Networks = ({ sidebarOpen, allNetworkResponseIsFetching }: NetworksProps) => {
  return (
    <div className="d-flex justify-content-center">
      <div className={`mx-auto tw-flex tw-w-full tw-items-center tw-justify-center tw-px-6`}>
        <div className="tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col">
          <OverviewHeader title="Private Networks" sidebarOpen={sidebarOpen} />
          <div className="py-4 h-100">
            <NetworksTable allNetworkResponseIsFetching={allNetworkResponseIsFetching} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Networks;
