import { LoadingPlaceholder } from '@components/Atoms/LoadingPlaceholder/LoadingPlaceholder';

type SkeletonTableProps = {
  columns: string[];
  headerClassname?: string;
  tableHeader?: string;
};
export const SkeletonTable = ({ columns, headerClassname = '', tableHeader }: SkeletonTableProps) => {
  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-lg tw-shadow">
      {!!tableHeader && (
        <div className="tw-flex tw-h-[69px] tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-p-3 tw-text-lg tw-font-medium tw-text-[#12263f]">
          {tableHeader}
        </div>
      )}
      <div
        className={`tw-font tw-flex tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white ${headerClassname}`}
      >
        {columns.map((column) => (
          <div className="tw-p-4">{column}</div>
        ))}
      </div>
      <div className="tw-flex tw-justify-center">
        <LoadingPlaceholder className="tw-border-b-solid tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-rounded-b-lg tw-border-b tw-border-none tw-border-sb-blue-grey-300 !tw-bg-white">
          <div className="tw-w-full tw-flex-col">
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={index}>
                <div className="tw-flex tw-h-14 tw-w-full tw-items-center tw-gap-6 tw-bg-sb-blue-grey-25 tw-p-3">
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                </div>
                <div className="tw-flex tw-h-14 tw-w-full tw-items-center tw-gap-2 tw-p-3">
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                  <div className="tw-h-2 tw-w-full tw-rounded tw-bg-slate-200"></div>
                </div>
              </div>
            ))}
          </div>
        </LoadingPlaceholder>
      </div>
    </div>
  );
};
